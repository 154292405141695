
import { defineComponent, ref } from 'vue';
import macLibrary from '@/components/view/common/mac-library';

export default defineComponent({
    components: {
        macLibrary
    },
    setup() {
        const identity = ref('dis');
        const disConfig = JSON.parse(localStorage.getItem('disConfig')!);
        if (!disConfig.isSubDis && disConfig.disCanCreateSubDis) {
            identity.value = 'subdis';
        }
        return {
            identity
        };
    }
});
